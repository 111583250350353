.progress{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.progress-bar{
  width: 30%;
  margin-top: 0.5%;
  height: 2%;
}
label{
    color: white;
    font-size: 2rem;
}
.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: auto;
  height: auto;
  animation: spin 2s linear infinite;
}

@keyframes spin {
0%{ 
    transform: rotate(0deg); 
  }
100%{ 
  transform: rotate(360deg); 
}
}
.nene{
  color: rgb(143, 250, 143);
}
.contenedor{
  position: absolute;
  margin: 40px;
  padding: 20px;

}
h1{
  position: flex;
  color: white;
}

p{
  position: absolute;
  
  left: 50%;
  transform: translate(-50%);
}

a{
  display: block;
  color: white;
  text-decoration: none;
  position: relative;
}

a::after{
  content: "";
  background: white;
  mix-blend-mode: exclusion;
  width: calc(100% + 20px);
  height: 0;
  position: absolute;
  bottom: -4px;
  left: -10px;
  transition: all .3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

a:hover::after{
  height: calc(100% + 8px)
}

